
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'

export default defineComponent({
  components: {
    TmAlert,
    TmFormLine,
    TmButton,
    TmModal,
  },
  setup() {
    const workspaceDelete = ref({
      agreement: false,
      subscription: false,
      textarea: '',
    })

    return {
      workspaceDelete,
    }
  },
})
